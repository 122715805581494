module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pepp Production","short_name":"peppen.se","start_url":"/","background_color":"#ffffff","theme_color":"#c8d1d3","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7f15fca8ce4a163471a824deb1f2fe85"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://peppen.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"27","matomoUrl":"https://matomo.accodeing.com","siteUrl":"https://peppen.se"},
    },{
      plugin: require('../node_modules/@accodeing/gatsby-theme-heimr/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Pepp Production","short_name":"peppen.se","background_color":"#ffffff","theme_color":"#c8d1d3","icon":"src/images/icon.png"},"siteUrl":"https://peppen.se","matomoSiteId":"27"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
